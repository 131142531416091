import ContactHero from "components/common/hero/ContactHero";
import LogoStripeSlider from "components/common/LogoStripeSlider";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import React from "react";

export default function ContactPage({
  pretitle = "Custom Quote",
  title = "Get a Custom Physical Security Quote",
  blurb = "Share your project with our team of experts to receive a comprehensive security consultation tailored to your needs, including smart cameras, access control, IoT sensors, and alarms.",
  formId = "89219072-bbdc-4c88-a130-21c4115a9839",
  telephone = "+18777466797",
  email = "sales@rhombus.com",
}) {
  return (
    <>
      <ContactHero
        pretitle={pretitle}
        title={title}
        blurb={blurb}
        formId={formId}
        telephone={telephone}
        email={email}
      />
      <LogoStripeSlider />
      <TrustedPartnerSection button={false} />
      <IndustryLeadersCards
        color="var(--nuetral-100)"
        title="Industry Leaders Trust Rhombus as Their Security Solution"
        preTitle="CUSTOMER SUCCESS STORIES"
      />
    </>
  );
}
